<template>
  <a-layout>
    <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%',background:'#fff'}" >
      <div style="display: flex;align-items: center;justify-content: center;">
          <div class="header"  >
              <img src="../assets/logo_gdy.svg" alt="" style="height: 40px;"/>
              <a-menu
                v-model:selectedKeys="selectedKeys"  mode="horizontal"
                :style="{ lineHeight: '64px' }"
              >
              <a-menu-item key="1" @click="changeFun(1)">产品功能</a-menu-item>
              <a-menu-item key="2" @click="changeFun(2)">产品价格</a-menu-item>
              <a-menu-item key="3" @click="changeFun(3)">动态 <a-badge dot/></a-menu-item>
              <a-menu-item key="4" @click="changeFun(4)">下载中心</a-menu-item>
              <a-menu-item key="5" @click="changeFun(5)">关于我们</a-menu-item>
            </a-menu>
            <div style="display: flex;justify-content: center;text-align: center;">
              一款只做商用车的综合管理软件&nbsp;&nbsp;&nbsp;&nbsp;
              <img src="../assets/icon/home/phone.svg" />
              <div>
                &nbsp;&nbsp;18068252929&nbsp;&nbsp;
              </div>
              <div>
                <a-button style="background-color: #1F39A9;color:#fff;font-size: 14px;font-weight: 800" @click="goToSys()">登录</a-button>
              </div>
              <!-- <a href="https://www.gdyapp.com/eshop" target="_break" style="display: flex;justify-content: center;">
                <div style="background-color: #1F39A9;width: 80px;">
                  <span style="font-weight: 800;font-size: 14px;line-height: 30px;color: #ffffff;">登录</span>
                </div>
              </a> -->
            </div>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content :style="{ padding: '0px 0px', marginTop: '64px' }">
      <Home v-if="i==1" />
      <ProPrice v-if="i==2"/>
      <UpdateList v-if="i==3"/>
      <DownloadFile v-if="i==4"/>
      <LinkUs v-if="i==5"/>
    </a-layout-content>
    <a-layout-footer style="display: flex;justify-content: center;align-items: center;">
      <div style="width: 80%;color: #fff;height: 130px;text-align: center;font-size: 13px;font-weight: 700;">
         <div style="float: left;margin-top: 15px;text-align: left;">
          <p>谷得一软件科技无锡有限公司</p>
          <p>咨询电话：18068252929</p>
          <p>地址：无锡市梁溪区崇安寺街道中山路333号-12G</p>
          <p>2023 - 2025 商车E铺版权所有 | 苏ICP备2023020209号-1 </p>
         </div>
         <div style="float: right;margin-top: 15px;">
          <p><img src="../assets/imgs/home/gdy_dy.svg" height="70px"></p>
          <p>扫一扫·关注抖音</p>
         </div>
      </div>
    </a-layout-footer>
  </a-layout>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import Home from '../view/Home.vue';
import ProPrice from '../view/ProPrice.vue';
import UpdateList from '../view/UpdateList.vue';
import DownloadFile from '../view/DownloadFile.vue';
import LinkUs from '../view/LinkUs.vue';
const i = ref(1);
const selectedKeys = ref<string[]>(['1']);
function changeFun(v){
  i.value = v
}
function goToSys(){
  window.open("https://www.gdyapp.com/eshop", '_blank');
}
</script>
<style scoped>

.header {
  text-align: center;
  width: 80%;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 15px;
  justify-content: space-between;
}
.site-layout .site-layout-background {
  background: #fff;
}

[data-theme='dark'] .site-layout .site-layout-background {
  background: #fff;
}
:deep(.ant-layout-footer){
  margin-top: 30px;
  background-color: #1F39A9;
}
</style>
