import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "update-top" }
const _hoisted_2 = { style: {"width":"75%"} }
const _hoisted_3 = { style: {"font-weight":"800"} }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 1,
  style: {"height":"200px"}
}

import { ref,onMounted,reactive } from 'vue';
import {getAllNewDataToFun,getAllLogToWeb} from '../api/api.js';
import {
  ClockCircleOutlined,
} from '@ant-design/icons-vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'UpdateList',
  setup(__props) {

const activeKey = ref('1');
const listUpData=reactive([]);
const listNewData=reactive([]);
const is_update_list = ref(false);
function updataDataFun(activeKey){
  console.log("activeKey====",activeKey);
  if(activeKey==2){//行业更新动态
    getAllNewDataToFun().then((res) => {
      if(res.code=='200'){
        let arry = res.result;
        listNewData.length = 0;
        for(let i=0;i<arry.length;i++){
          listNewData.push(arry[i]);
        }
      }
    });
  }else{//产品更新动态
    getAllLogToWeb().then((res) => {
      if(res){
        if(res.code=='200'){
          let arry = res.result;
          listUpData.length = 0;
          for(let i=0;i<arry.length;i++){
            listUpData.push(arry[i]);
          }
          is_update_list.value = true;
        }
      }else{
        is_update_list.value = false;
      }
    });
  }
}
onMounted(()=>{
  updataDataFun(1);
});

return (_ctx: any,_cache: any) => {
  const _component_a_timeline_item = _resolveComponent("a-timeline-item")!
  const _component_a_timeline = _resolveComponent("a-timeline")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_tabs, {
        activeKey: activeKey.value,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((activeKey).value = $event)),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (updataDataFun(activeKey.value)))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tab_pane, {
            key: "1",
            tab: "产品更新动态"
          }, {
            default: _withCtx(() => [
              (is_update_list.value)
                ? (_openBlock(), _createBlock(_component_a_timeline, {
                    key: 0,
                    style: {"margin-top":"20px"}
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(listUpData, (a) => {
                        return (_openBlock(), _createBlock(_component_a_timeline_item, { key: a }, {
                          dot: _withCtx(() => [
                            _createVNode(_unref(ClockCircleOutlined), { style: {"font-size":"16px"} })
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("p", _hoisted_3, "[" + _toDisplayString(a.createTime) + "]" + _toDisplayString(a.versonNo), 1)
                            ]),
                            _createElementVNode("div", {
                              innerHTML: a.versonDesc
                            }, null, 8, _hoisted_4)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (!is_update_list.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                    _createElementVNode("p", null, "暂无动态", -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey"])
    ])
  ]))
}
}

})