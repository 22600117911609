<template>
  <div class="home-top" >
    <div style="width: 75%;">
      <a-row>
        <a-col :span="12"  style="display: flex;align-items: center;justify-content: center;">
          <div style="height: 150px;">
            <a-typography-title :level="2" style="color: #fff;">助力商用车经销商数字化转型升级</a-typography-title>
            <a-typography-title :level="5" style="color: #fff;">云端轻松实现销售、售后、财务等全业务流程数字化！</a-typography-title>
          </div>
        </a-col>
        <a-col :span="12" style="display: flex;align-items: center;justify-content: center;">
          <div>
            <a-card title="申请免费体验" :bordered="false" style="width: 300px;font-size: 16px;">
                <div class="components-input-demo-presuffix">
                <a-input  v-model:value="userPhone" placeholder="请输入您的手机号" style="height: 40px;">
                  <template #prefix>
                    <PhoneOutlined />
                  </template>
                </a-input>
                <br />
                <br />
                <a-input  v-model:value="userName" placeholder="请输入您的姓名" style="height: 40px;">
                  <template #prefix>
                    <UserOutlined />
                  </template>
                </a-input>
                <br />
                <br />
                <a-button type="ant-btn ant-btn-primary ant-btn-large ant-btn-block" @click="applyUser" style="background-color:#1F39A9;height: 45px;">
                  <span style="font-size: 14px;font-weight: 800;">申请试用</span>
                </a-button>
              </div>
            </a-card>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <!-- 通知 -->
  <div style="height: 40px;width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;margin-top: 2px;background-color: #fff;">
    <div style="width: 75%;">
      <a-row>
        <a-col :span="1" >
          <div style="height: 30px;display: flex;align-items: center;justify-content: center;">
            <SoundFilled   style="size: 14px;color:#1F39A9"/>
          </div>
        </a-col>
        <a-col :span="23" style="text-align: left;font-size: 13px;" >
          <a-carousel autoplay dot-position="left" :dots="false" v-if="nuList.length>0">
              <span  v-for="item in nuList" :key="item" ><a href="#" @click="showDocView(item)"> {{ item.createTime }}[{{ item.versonNo }}] </a> </span>
          </a-carousel>
        </a-col>
      </a-row>
    </div>
  </div>
  <!-- 核心功能-销售业务 -->
  <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;margin-top: 2px;background-color: #fff;">
    <div style="width: 75%;margin-top: 30px;margin-bottom: 30px;">
      <a-row >
        <a-col :span="24" >
          <div  style="height: 80px;" >
            <a-typography-title :level="5" style="color: #1F39A9;font-size: 24px;font-weight: 800;">核心功能-销售业务</a-typography-title>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[30,30]">
        <a-col class="gutter-row" :span="6" v-for="item in saleList" :key = "item">
          <a>
            <div  style="height: 200px;width: 100%;color: #000;" >
              <div class="gutter-box">
                <a-row>
                  <a-col :span="24" >
                    <div style="height: 60px;display: flex;align-items: center;justify-content: center;font-weight: 800;font-size: 16px;">
                      {{ item.funModel }}
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24" >
                    <div style="height: 60px;display: flex;align-items: center;justify-content: center;margin-left: 15px;margin-right: 15px;line-height: 25px">
                      {{ item.remark }}
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="16" >
                  </a-col>
                  <a-col :span="8" v-if="item.videoUrl != null && item.videoUrl != '' ">
                    <div style="height: 80px;font-size:13px;display: flex;align-items: center;justify-content: center;text-align: right;font-weight: 800;" @click=showModal(item.videoUrl)>
                     功能演示
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a>
        </a-col>
      </a-row>
    </div>
  </div>
  <!-- 核心功能-销售业务 -->
  <div style="width: 100%;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #fff;">
    <div style="width: 75%;margin-bottom: 30px">
      <a-row >
        <a-col :span="24" >
          <div  style="height: 80px;" >
            <a-typography-title :level="5" style="color: #1F39A9;font-size: 24px;font-weight: 800;">核心功能-售后业务</a-typography-title>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="[30,30]">
        <a-col class="gutter-row" :span="6" v-for="item in assList" :key = "item">
          <a>
            <div  style="height: 200px;width: 100%;color: #000;" >
              <div class="gutter-box">
                <a-row>
                  <a-col :span="24" >
                    <div style="height: 60px;display: flex;align-items: center;justify-content: center;font-weight: 800;font-size: 16px;">
                      {{ item.funModel }}
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="24" >
                    <div style="height: 60px;display: flex;align-items: center;justify-content: center;margin-left: 15px;margin-right: 15px;line-height: 25px">
                      {{ item.remark }}
                    </div>
                  </a-col>
                </a-row>
                <a-row>
                  <a-col :span="16" >
                  </a-col>
                  <a-col :span="8" v-if="item.videoUrl != null && item.videoUrl != '' ">
                    <div style="height: 80px;font-size:13px;display: flex;align-items: center;justify-content: center;text-align: right;font-weight: 800;" @click=showModal(item.videoUrl)>
                     功能演示
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a>
        </a-col>
      </a-row>
    </div>
  </div>
  <div style="width: 100%;height: 120px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #F5F5F5;">
    <div style="width: 75%;">
      <a-row >
        <a-col :span="24" >
          <div  style="height: 60px;" >
            <a-typography-title :level="5" style="color: #1F39A9;font-size: 24px;font-weight: 800;">产品特点</a-typography-title>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
  <div style="width: 100%;height: 180px;text-align: center;display: flex;align-items: center;justify-content: center;">
    <div style="width: 75%;">
        <a-row :gutter="[15,15]">
          <a-col :span="8" >
            <a-card title="安全性">
                <template #extra><SafetyCertificateFilled  style="color: #DE7A44;"/></template>
                <div style="text-align: center;height: 100px;font-size: 16px;">
                  云端存储、数据加密、分级授权
                </div>
              </a-card>
          </a-col>
          <a-col :span="8" >
            <a-card title="便捷性">
                <template #extra><TabletFilled   style="color: #DE7A44;"/></template>
                <div style="text-align: center;height: 100px;font-size: 16px;">
                  多端数据互通，业务操作直观简洁
                </div>
              </a-card>
          </a-col>
          <a-col :span="8" >
            <a-card title="高效性">
                <template #extra><RocketFilled  style="color: #DE7A44;"/></template>
                <div style="text-align: center;height: 100px;font-size: 16px;">
                  移动APP快捷办理、数据智能分析
                </div>
              </a-card>
          </a-col>
      </a-row>
    </div>
  </div>
  <a-modal v-model:open="open" title="功能视频演示" :maskClosable="false" width="850px" cancelText="关闭" :closable="false" :footer="null">
      <iframe :src="bviewUrl" 
        scrolling="no" border="0" 
        frameborder="no" framespacing="0" 
        allowfullscreen="true" 
        width="800px" height="450px">
      </iframe>
      <div style="text-align: center;">
        <a-button type="primary" style="background-color:#1F39A9 ;"  @click="handleOk">
          <span style="font-size: 14px;font-weight: 800;">关闭</span>
        </a-button>
      </div>
  </a-modal>
  <a-modal v-model:open="openApply" title="申请试用成功" :maskClosable="false" width="850px" cancelText="关闭" :closable="false" :footer="null">
      <div v-for="item in resultStr" :key="item">
          <p>{{item}}</p>
      </div>
      <div><a href="https://www.gdyapp.com/eshop/" target="_break"> 点击试用</a></div>
      <div style="text-align: center;">
        <a-button type="primary" style="background-color:#1F39A9 ;"  @click="handleOkApply">
          <span style="font-size: 14px;font-weight: 800;">关闭</span>
        </a-button>
      </div>
  </a-modal>
  <a-modal v-model:open="openDocView" title="动态详情" :maskClosable="false" width="850px" cancelText="关闭" :closable="false" :footer="null">
      <div>
        <a-divider />
        <br/>
        <h4>{{ funModel }}</h4>
        <div v-html="docBody"></div>
      </div>
      <div style="text-align: center;">
        <a-button type="primary" style="background-color:#1F39A9 ;"  @click="handleOkDocView">
          <span style="font-size: 14px;font-weight: 800;">关闭</span>
        </a-button>
      </div>
  </a-modal>
  <contextHolder />
</template>
<script lang="ts" setup>
import { ref,reactive } from 'vue';
import {applyUserFun,getDataToHomeFun} from '../api/api.js';
import { UserOutlined,PhoneOutlined,SoundFilled,SafetyCertificateFilled,RocketFilled,TabletFilled } from '@ant-design/icons-vue';
import { NotificationPlacement, notification } from 'ant-design-vue';
const userName = ref();
const userPhone = ref();
const resultStr = reactive([]);
const nuList = reactive([]);
const saleList = reactive([]);
const assList = reactive([]);
const bviewUrl = ref();
const funModel = ref();
const docBody = ref();
const open = ref<boolean>(false);
const showModal = (videoUrl) => {
  bviewUrl.value = videoUrl;
  open.value = true;
};
const handleOk = () => {
  open.value = false;
};
const [api,contextHolder] = notification.useNotification();
const openMessage = (placement: NotificationPlacement) => openNotification(placement);
const openNotification = (placement: NotificationPlacement) => {
  api.info({
    message: `提醒`,
    description:
      '请输入11位手机号和姓名，方便我们及时与您联系！',
    placement,
  });
};
const openApply = ref<boolean>(false);
const showApplyModal = () => {
  openApply.value = true;
};
const handleOkApply = () => {
  openApply.value = false;
};
getDataToHome();
function getDataToHome(){
  getDataToHomeFun().then((res) => {
    if(res){
      if(res.code=='200'){
        let nuArry = res.result.nuList;
        for(let i=0;i<nuArry.length;i++){
          nuList.push(nuArry[i]);
        }
        //销售核心功能
        let saArry = res.result.saleList;
        for(let i=0;i<saArry.length;i++){
          saleList.push(saArry[i]);
        }
        //售后核心功能
        let asArry = res.result.assList;
        for(let i=0;i<asArry.length;i++){
          assList.push(asArry[i]);
        }
      }
    }
  });
}
function applyUser(){
  if(userName.value!=null && userPhone.value!=null){
    if(userPhone.value.length != 11){
      openMessage('topRight');
    }else{
      // let res = applyUserFun(userName.value,userPhone.value);
      applyUserFun(userName.value,userPhone.value).then((res) => {
          if(res.result=='200'){
            resultStr.length = 0;
            resultStr.push("申请成功，可使用以下账号进行试用!");
            resultStr.push("角色/账号/密码，店总/eshoptest/000000");
            resultStr.push("试用过程中，如果有任何问题，请及时和我们联系！");
            showApplyModal();
          }
      });
    }
  }else{
    openMessage('topRight');
  }
}
const openDocView = ref<boolean>(false);
const showDocView = (obj) => {
  funModel.value = obj.createTime + '['+obj.versonNo+']';
  docBody.value = obj.versonDesc;
  openDocView.value = true;
};
function handleOkDocView(){
  openDocView.value = false;
}
</script>
<style scoped>
:deep(.slick-slide) {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
}
:deep(.slick-slide h3) {
  color: #fff;
}
.gutter-box {
  /* background: linear-gradient(to right, #1F39A9, #2342d0); */
  background-color: #F5F5F5;
  border-radius: 5px;
  height: 200px;
  padding: 8px 0;
}
.gutter-box:hover {
  background-color: #1F39A9;
  color: #fff;
}
.home-top{
  height: 400px;width: 100%;text-align: center;
  display: flex;align-items: center;justify-content: center;
  /* background: linear-gradient(to top, #1F39A9, #beccff); */
  background:#1F39A9;
}
:deep(.ant-divider-horizontal){
  margin:0px;
}
</style>
