<template>
  <div class="update-top">
    <div style="width: 75%;">
      <a-tabs v-model:activeKey="activeKey" @change="updataDataFun(activeKey)" >
        <a-tab-pane key="1" tab="产品更新动态">
          <a-timeline style="margin-top: 20px;" v-if="is_update_list">
            <a-timeline-item v-for="a in listUpData" :key = "a" > 
              <template #dot><clock-circle-outlined style="font-size: 16px" /></template>
             <div>
              <p style="font-weight: 800;">[{{a.createTime}}]{{a.versonNo}}</p>
              </div>
             <div v-html="a.versonDesc"></div>
            </a-timeline-item>
          </a-timeline>
          <div v-if="!is_update_list" style="height: 200px;">
            <p>暂无动态</p>
          </div>
        </a-tab-pane>
        <!-- <a-tab-pane key="2" tab="行业新闻动态"  >
          <a-list item-layout="vertical" size="large"  >
              <a-list-item  v-for="b in listNewData" :key = "b" >
                <template #extra>
                  <img
                    width="80"
                    alt="logo"
                    :src="'http://127.0.0.1:8808/cvcloud-boot/sys/common/static/'+b.imgUrl"
                  />
                </template>
                <a-list-item-meta>
                  <template #title>
                    <a :href="b.funUrl" target="_break">[{{b.createTime}}]{{ b.funModel }}</a>
                  </template>
                </a-list-item-meta>
                <div v-html="b.remark"></div>
              </a-list-item>
          </a-list>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref,onMounted,reactive } from 'vue';
import {getAllNewDataToFun,getAllLogToWeb} from '../api/api.js';
import {
  ClockCircleOutlined,
} from '@ant-design/icons-vue';
const activeKey = ref('1');
const listUpData=reactive([]);
const listNewData=reactive([]);
const is_update_list = ref(false);
function updataDataFun(activeKey){
  console.log("activeKey====",activeKey);
  if(activeKey==2){//行业更新动态
    getAllNewDataToFun().then((res) => {
      if(res.code=='200'){
        let arry = res.result;
        listNewData.length = 0;
        for(let i=0;i<arry.length;i++){
          listNewData.push(arry[i]);
        }
      }
    });
  }else{//产品更新动态
    getAllLogToWeb().then((res) => {
      if(res){
        if(res.code=='200'){
          let arry = res.result;
          listUpData.length = 0;
          for(let i=0;i<arry.length;i++){
            listUpData.push(arry[i]);
          }
          is_update_list.value = true;
        }
      }else{
        is_update_list.value = false;
      }
    });
  }
}
onMounted(()=>{
  updataDataFun(1);
});
</script>
<style scoped>
.update-top{
  margin-top: 2px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color:#fff;
  /* width: 100%;height: 180px;text-align: center;display: flex;align-items: center;justify-content: center;background-color: #fff; */
}
</style>
